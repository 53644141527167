.about-wrapper {
  display: flex;
  justify-content: center;
}
.about {
  max-width: 1080px;
  display: flex;
  flex-direction: column;
  margin: 3rem 0;
  &__heading {
    max-width: 1080px;
    text-align: center;

    &--title {
      h4 {
        color: #4f6f52;
        font-weight: 550;
        margin-bottom: 0;
      }
    }
    &--divider {
      align-self: center;
      // width: 6rem;
      border: 2px solid #4f6f52;
      margin: 2.5rem 0;
    }
  }

  &__section {
    display: flex;
    width: 100%;
    justify-content: space-between;
    margin: 1rem 0;

    &__subsection {
      max-width: 500px;
      // margin: 1rem 2rem;
      // width: 50%;

      &--title {
        h4 {
          color: #4f6f52;
          font-weight: 550;
          margin-bottom: 0;
        }
      }
      &--divider {
        width: 6rem;
        border: 2px solid #4f6f52;
        margin: 0.5rem 0;
      }
    }

    &__subsectionPic {
      background-color: #4f6f52;
      // width: 50%;
      img {
        width: 400px;
      }
      display: flex;
      justify-content: center;
      padding: 1rem;
      // margin: 1rem 2rem;

      &--title {
        h4 {
          color: #4f6f52;
          font-weight: 550;
          margin-bottom: 0;
        }
      }
      &--divider {
        width: 6rem;
        border: 2px solid #4f6f52;
        margin: 0.5rem 0;
      }
    }
  }
}
