.ender-wrapper {
  display: flex;
  justify-content: center;
  height: 40vh;
  background-color: #4f6f52;
}

.ender {
  max-width: 1280px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  &__titlebox {
    // text-align: left;
    // width: 1280px;
    color: white;
    margin: 4rem;

    h1 {
      font-size: 5vw;
      font-weight: 550;
      //   margin-bottom: 0;
      text-shadow: 1px 1px 20px rgba(60, 81, 58, 0.9);
    }
    h2 {
      //   margin-top: 0;
    }
    button {
      width: 170px;
      background-color: #d2e3c8;
      color: #4f6f52;
      border: 0px;
      border-radius: 50px;
      transition: 0.2s;
      // padding: ;
      padding: 4px 0 6px 0;
      p {
        font-size: 1.2em;
        margin: 0;
        font-weight: 600;
        // line-height: 2rem;
      }
    }
    button:hover {
      cursor: pointer;
      background-color: #4f6f52;
      color: #d2e3c8;
    }
  }
  &__booking {
    text-align: center;
    margin: 4rem;

    img {
      margin: 1rem;
    }
    button {
      width: 170px;
      background-color: #d2e3c8;
      color: #4f6f52;
      border: 0px;
      border-radius: 50px;
      transition: 0.2s;
      // padding: ;
      padding: 4px 0 6px 0;
      p {
        font-size: 1.2em;
        margin: 0;
        font-weight: 600;
        // line-height: 2rem;
      }
    }
    button:hover {
      cursor: pointer;
      background-color: #2f4331;
      color: #d2e3c8;
    }
  }
}
