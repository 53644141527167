// * {
//   box-sizing: border-box;
//   margin: 0;
//   padding: 0;
// }
// html {
//   font-size: 16px;
// }
.caro-body {
  //   align-items: center;
  //   display: grid;
  //   height: 100vh;
  //   font-family: system-ui;
  //   justify-content: center;
  padding: 1rem 1rem;
  h1 {
    color: #4f6f52;
  }
  &__divider {
    width: 6rem;
    border: 2px solid #4f6f52;
    margin: 1rem 0;
  }
}
.carousel__list {
  //   align-items: center;
  display: grid;
  //   grid-row-gap: 1.25rem;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr auto;
  max-width: 40rem;
  overflow: hidden;
  //   padding: 1rem 2rem;
  list-style-type: none;
  margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  padding-inline-start: 0px;
}
.carousel__item {
  grid-column: 1;
  grid-row: 1;
  line-height: 1.2;
  //   text-align: center;
  transition: transform 0.4s;
  cursor: pointer;
}
.carousel__item.active {
  transform: translateX(0);
  opacity: 1;
}
.carousel__item.left {
  transform: translateX(-110%);
  opacity: 0;
}
.carousel__item.right {
  transform: translateX(110%);
  opacity: 0;
}
.carousel__indicator {
  display: grid;
  grid-gap: 1rem;
  grid-template-columns: repeat(5, 1fr);
  grid-row: 2;
  justify-self: center;
}
.carousel__dot {
  background-color: white;
  border-radius: 50%;
  border: 0.0625rem solid #696a6b;
  display: block;
  height: 0.5rem;
  width: 0.5rem;
}
.carousel__dot.active {
  background-color: #696a6b;
}

.carousel__name {
  display: block;
  //   font-style: normal;
  //   font-weight: 600;
}
.carousel__quote {
  font-style: italic;
  line-height: 1.5;
  display: block;
  margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
}
