.differences-wrapper {
  // width: 100vw;
  display: flex;
}

.differences {
  display: flex;
  width: 100%;
  height: 60vh;

  &__section1 {
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    width: 100%;

    background-image: linear-gradient(
        90deg,
        rgba(26, 36, 28, 0.55) 20%,
        rgba(0, 0, 0, 0) 75%
      ),
      url(../../assets/banners/retention.jpeg) !important;
    background-size: cover;
    // background-position: 50%;
    // padding-top: 180px;

    h1 {
      color: white;
      font-size: 3vw;
      font-weight: 550;
      margin-bottom: 0;
      text-shadow: 1px 1px 20px rgba(60, 81, 58, 0.9);
    }
    h2 {
      color: rgba(0, 0, 0, 0);
      font-size: 2vw;
    }
  }
  &__section1:hover {
    cursor: pointer;
    background-image: linear-gradient(
        90deg,
        rgba(173, 176, 173, 0.55) 100%,
        rgba(0, 0, 0, 0) 75%
      ),
      url(../../assets/banners/retention.jpeg) !important;
    background-size: cover;
    // background-position: 50%;
    // padding-top: 180px;

    h1 {
      font-size: 2.5vw;
    }
    h2 {
      color: white;
      font-size: 2vw;
      text-shadow: 1px 1px 20px rgba(60, 81, 58, 0.9);
    }
  }
  &__section2 {
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    width: 100%;

    background-image: linear-gradient(
        90deg,
        rgba(26, 36, 28, 0.55) 20%,
        rgba(0, 0, 0, 0) 75%
      ),
      url(../../assets/banners/money.jpeg) !important;
    background-size: cover;
    // background-position: 50%;
    // padding-top: 180px;

    h1 {
      color: white;
      font-size: 3vw;
      font-weight: 550;
      margin-bottom: 0;
      text-shadow: 1px 1px 20px rgba(60, 81, 58, 0.9);
    }
    h2 {
      color: rgba(0, 0, 0, 0);
      font-size: 2vw;
    }
  }
  &__section2:hover {
    cursor: pointer;
    background-image: linear-gradient(
        90deg,
        rgba(173, 176, 173, 0.55) 100%,
        rgba(0, 0, 0, 0) 75%
      ),
      url(../../assets/banners/money.jpeg) !important;
    background-size: cover;
    // background-position: 50%;
    // padding-top: 180px;

    h1 {
      font-size: 2.5vw;
    }
    h2 {
      color: white;
      font-size: 2vw;
      text-shadow: 1px 1px 20px rgba(60, 81, 58, 0.9);
    }
  }
  &__section3 {
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    width: 100%;

    background-image: linear-gradient(
        90deg,
        rgba(26, 36, 28, 0.55) 20%,
        rgba(0, 0, 0, 0) 75%
      ),
      url(../../assets/banners/lawpic.jpg) !important;
    background-size: cover;
    // background-position: 50%;
    // padding-top: 180px;

    h1 {
      color: white;
      font-size: 3vw;
      font-weight: 550;
      margin-bottom: 0;
      text-shadow: 1px 1px 20px rgba(60, 81, 58, 0.9);
    }
    h2 {
      color: rgba(0, 0, 0, 0);
      font-size: 2vw;
    }
  }
  &__section3:hover {
    cursor: pointer;
    background-image: linear-gradient(
        90deg,
        rgba(173, 176, 173, 0.55) 100%,
        rgba(0, 0, 0, 0) 75%
      ),
      url(../../assets/banners/lawpic.jpg) !important;
    background-size: cover;
    // background-position: 50%;
    // padding-top: 180px;

    h1 {
      font-size: 2.5vw;
    }
    h2 {
      color: white;
      font-size: 2vw;
      text-shadow: 1px 1px 20px rgba(60, 81, 58, 0.9);
    }
  }
}
