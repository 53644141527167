.home {
  display: flex;
  width: 100%;
  &__left {
    align-items: center;
    text-align: center;
    // width: 50%;
    padding: 150px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    // background-color: #4f6f52;
    background-size: cover;
    background-position: 50%;
    background-image: linear-gradient(
        90deg,
        rgba(56, 78, 60, 0.88) 100%,
        rgba(0, 0, 0, 0) 100%,
        rgba(21, 30, 22, 0.2) 100%
      ),
      url(../../assets/banners/teamleader.jpg) !important;
    // color: white;
    color: #f1f6ee;
    h1 {
      font-size: 3vw;
      margin-bottom: 0;
    }
    button {
      width: 170px;
      background-color: #d2e3c8;
      color: #4f6f52;
      border: 0px;
      border-radius: 50px;
      transition: 0.2s;
      // padding: ;
      padding: 4px 0 6px 0;
      a {
        text-decoration: none;
        font-size: 1.2em;
        margin: 0;
        font-weight: 600;
        color: #4f6f52;
        // line-height: 2rem;
      }
    }
    button:hover {
      cursor: pointer;
      background-color: #4f6f52;
      a {
        color: #d2e3c8;
      }
    }
  }
  &__right {
    align-items: center;
    text-align: center;
    // width: 50%;
    padding: 90px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    // background-color: #d2e3c8;
    // background-color: #e8f1e3;
    // background-color: #f6f9f4;
    border: 60px solid white;
    background-color: #f1f6ee;
    color: #4f6f52;
    h1 {
      font-size: 3vw;
      margin-bottom: 0;
    }
    button {
      width: 170px;
      background-color: #d2e3c8;
      color: #4f6f52;
      border: 0px;
      border-radius: 50px;
      transition: 0.2s;
      // padding: ;
      padding: 4px 0 6px 0;
      a {
        text-decoration: none;
        font-size: 1.2em;
        margin: 0;
        font-weight: 600;
        color: #4f6f52;
        // line-height: 2rem;
      }
    }
    button:hover {
      cursor: pointer;
      background-color: #4f6f52;
      a {
        color: #d2e3c8;
      }
    }
  }
}
.reviews {
  display: flex;
  justify-content: center;
  // background-color: #f1f6ee;
  &__container {
    display: flex;
    width: 1280px;
    margin: 60px 0;
  }
}
