.faq-wrapper {
  display: flex;
  justify-content: center;
}
.faq {
  max-width: 1080px;
  display: flex;
  flex-direction: column;
  margin: 3rem 0;
  &__heading {
    max-width: 1080px;
    text-align: left;

    &--title {
      h4 {
        color: #4f6f52;
        font-weight: 550;
        margin-bottom: 0;
      }
    }
    &--divider {
      align-self: center;
      width: 6rem;
      border: 2px solid #4f6f52;
      margin: 2.5rem 0;
    }
  }

  &__section {
    display: flex;
    width: 100%;
    flex-direction: column;

    margin: 1rem 0;
    &__content {
      display: grid;
      grid-template-rows: 0fr;

      transition: grid-template-rows 0.1s ease-in;
      &--open {
        display: grid;
        grid-template-rows: 1fr;
        background-color: #f1f6ee;
        padding: 1rem;
      }
    }

    &__subsection {
      //   max-width: 500px;
      margin: 1rem 0;
      padding: 1rem;
      // width: 50%;
      cursor: pointer;

      &--title {
        h4 {
          color: #4f6f52;
          font-weight: 550;
          margin-bottom: 0;
        }
      }
      &--divider {
        width: 6rem;
        border: 2px solid #4f6f52;
        margin: 0.5rem 0;
      }
    }
    &__subsection:hover {
      background-color: #f1f6ee;
    }

    &__subsectionPic {
      background-color: #4f6f52;
      // width: 50%;

      display: flex;
      justify-content: center;
      padding: 2rem;
      // margin: 1rem 2rem;

      &--title {
        h4 {
          color: #4f6f52;
          font-weight: 550;
          margin-bottom: 0;
        }
      }
      &--divider {
        width: 6rem;
        border: 2px solid #4f6f52;
        margin: 0.5rem 0;
      }
    }
  }
}
