.clients-wrapper {
  display: flex;
  justify-content: center;
}

.clients {
  display: flex;
  margin: 3rem 0;

  &__form {
    margin: 0 4rem;
    &--title {
      h1 {
        color: #4f6f52;
        font-weight: 550;
      }
    }
    form {
      display: flex;
      flex-direction: column;

      input {
        width: 100%;
        padding: 12px 20px;
        margin: 8px 0;
        display: inline-block;
        border: 1px solid #ccc;
        border-radius: 4px;
        box-sizing: border-box;
      }
      button {
        // width: 170px;
        background-color: #d2e3c8;
        color: #4f6f52;
        border: 0px;
        border-radius: 50px;
        transition: 0.2s;
        margin: 8px 0;
        // padding: ;
        padding: 4px 0 6px 0;
        p {
          font-size: 1.2em;
          margin: 0;
          font-weight: 600;
          // line-height: 2rem;
        }
      }
      button:hover {
        cursor: pointer;
        background-color: #4f6f52;
        color: #d2e3c8;
      }
    }
  }
  &__imgbox {
    margin: 3rem 3rem;
  }
}
