.scroll-body {
  padding: 1rem 2rem;
  margin-right: 3rem;

  &__title {
    h1 {
      color: #4f6f52;
    }
  }
  &__divider {
    width: 6rem;
    border: 2px solid #4f6f52;
    margin: 1rem 0;
  }
}
.scrollbox {
  display: flex;
  flex-direction: column;
  text-align: left;
  max-width: 80rem;
  height: 300px;
  overflow-y: scroll;
  padding: 0rem 1rem 0rem 0rem;
}

//scrollbar & thumb
.scrollbox::-webkit-scrollbar {
  width: 14px;
}
.scrollbox::-webkit-scrollbar-thumb {
  border: 4px solid rgba(0, 0, 0, 0);
  background-clip: padding-box;
  -webkit-border-radius: 7px;
  background-color: #dddddd;
  -webkit-box-shadow: inset -1px -1px 0px rgba(0, 0, 0, 0.05),
    inset 1px 1px 0px rgba(0, 0, 0, 0.05);
}
.scrollbox::-webkit-scrollbar-button {
  width: 0;
  height: 0;
  display: none;
}
.scrollbox::-webkit-scrollbar-corner {
  background-color: transparent;
}
