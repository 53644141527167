.footer {
  &__top {
    display: flex;
    justify-content: center;
    background-color: #d2e3c8;
    color: #4f6f52;
    //   padding: 60px 0;
    &__left {
      width: 20%;
      text-align: right;
      margin: 30px 10px;

      * {
        margin: 0;
      }
    }
    &__divider {
      width: 2px;
      //   height: 0px;
      margin: 30px 10px;
      background-color: #739072;
    }

    &__right {
      width: 20%;
      text-align: left;
      margin: 30px 10px;
      &--navtag {
      }
      &--navtag:hover {
        color: #739072;
        cursor: pointer;
      }
      * {
        margin: 0;
      }
    }
  }
  &__bottom {
    &__logos {
      display: flex;
      justify-content: space-evenly;
      margin: 20px;
      img {
        border-radius: 50%;
      }
    }
    &--copyright {
      text-align: center;
      font-size: small;
      color: lightgray;
    }
  }
}
