@import url("https://fonts.googleapis.com/css2?family=Merriweather:ital,wght@0,300;0,400;0,700;0,900;1,300;1,400;1,700;1,900&family=Underdog&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Barlow+Condensed:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Merriweather:ital,wght@0,300;0,400;0,700;0,900;1,300;1,400;1,700;1,900&display=swap");
h1 {
  font-family: "Merriweather", serif;
  font-weight: 400;
  font-style: normal;
}
h2 {
  font-family: "Barlow Condensed", sans-serif;
  font-weight: 500;
  font-style: normal;
}
h3 {
  font-family: "Barlow Condensed", sans-serif;
  font-weight: 500;
  font-style: normal;
}
h4 {
  font-family: "Merriweather", serif;
  font-weight: 400;
  font-style: normal;
}

p {
  font-family: "Barlow Condensed", sans-serif;
  font-weight: 400;
  font-style: normal;
  font-size: 22px;
}

button {
  font-family: "Barlow Condensed", sans-serif;
  font-weight: 400;
  font-style: normal;
  font-size: 22px;
}

@media screen and (max-width: 1000px) {
  .home {
    flex-direction: column;
    &__left {
      // width: 100%;
    }
    &__right {
      // width: 100%;
    }
  }
  .reviews__container {
    display: flex;
    flex-direction: column;
    .scroll-body {
      margin: 0;
    }
    .caro-body {
      padding: 1rem 2rem;
    }
  }
  .differences {
    flex-direction: column;
    height: auto;
    &__section1,
    &__section2,
    &__section3 {
      height: 500px;
      h1 {
        font-size: 32px;
      }
    }
    &__section1:hover,
    &__section2:hover,
    &__section3:hover {
      // height: 500px;
      h1 {
        font-size: 28px;
      }
    }
  }
  .about__heading {
    padding: 2rem;
  }
  .about__section {
    flex-direction: column;
    &__subsection {
      max-width: fit-content;
      padding: 2rem;
    }
    &__subsectionPic img {
      width: 100%;
    }
  }
  .clients {
    flex-direction: column;
    justify-content: center;
    &__form {
      // width: 100%;
      margin: 2rem;
    }
    &__imgbox {
      width: auto;
      margin: 0rem 0;
      img {
        width: 100% !important;
      }
    }
  }
  .faq-wrapper {
    padding: 2rem;
  }
}
@media screen and (max-width: 720px) {
  .App {
    overflow-x: hidden;
  }
  .NavBar {
    flex-direction: column;
    justify-content: center;
    &__logoAndTitle {
      // display: static;
      margin: 0;
      h1 {
        font-size: 22px;
        text-align: center;
      }
    }
    .nav {
      display: flex;
      justify-content: space-between;
    }
    button {
      margin: 0;
      font-size: 20px;
    }
  }
  .banner {
    height: auto;
    padding: 80px 0;
  }
  .banner,
  .bannerAbout,
  .bannerClient,
  .bannerBlog,
  .bannerCareers,
  .bannerFaq {
    &__titlebox {
      text-align: center;
      h1 {
        font-size: 3em;
      }
    }
  }
}

@media screen and (max-width: 500px) {
  .home__left,
  .home__right {
    padding: 40px;
  }
  .ender {
    flex-direction: column;
    justify-content: space-evenly;

    &__titlebox {
      margin: 0;
      h1 {
        font-size: 32px;
      }
    }
  }
  .footer__top {
    flex-direction: column;
    align-items: center;
    text-align: center;
    &__divider {
      display: none;
    }
    &__left {
      width: auto;
    }
  }
}
