.NavBar {
  display: flex;
  justify-content: space-between;
  background-color: #4f6f52;
  color: white;
  height: 102px;
  padding: 6px;

  button {
    border: none;
    height: 100%;
    cursor: pointer;
    margin: 0 0.7vw;
    color: white;
    background-color: transparent;
    transition: 0.3s;
    font-weight: 425;
    // font-size: 20px;
  }
  button:hover {
    color: #d2e3c8;
  }

  &__logoAndTitle {
    display: flex;
    text-align: center;
    align-items: center;
    margin: 0 1vw;
    h1 {
      font-size: 49px;
    }
    cursor: pointer;
  }
}
