.banner {
  height: 90vh;
  width: 100%;
  overflow: hidden;
  display: flex;
  justify-content: center;
  background-image: linear-gradient(
      90deg,
      rgba(26, 36, 28, 0.95) 20%,
      rgba(0, 0, 0, 0) 75%
    ),
    url(../../assets/banners/callerwithscript.jpg) !important;
  background-size: cover;
  background-position: 50%;
  padding-top: 180px;

  &__titlebox {
    text-align: left;
    width: 1280px;
    color: white;

    h1 {
      font-size: 6vw;
      font-weight: 550;
      margin-bottom: 0;
      text-shadow: 1px 1px 20px rgba(60, 81, 58, 0.9);
    }
    h2 {
      margin-top: 0;
    }
    button {
      width: 170px;
      background-color: #d2e3c8;
      color: #4f6f52;
      border: 0px;
      border-radius: 50px;
      transition: 0.2s;
      // padding: ;
      padding: 4px 0 6px 0;
      p {
        font-size: 1.2em;
        margin: 0;
        font-weight: 600;
        // line-height: 2rem;
      }
    }
    button:hover {
      cursor: pointer;
      background-color: #4f6f52;
      color: #d2e3c8;
    }
  }
}
// rgba(21, 30, 22, 0.2) 100%

.bannerAbout {
  height: 50vh;
  width: 100%;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  background-image: linear-gradient(
      90deg,
      rgba(26, 36, 28, 0.95) 30%,
      rgba(0, 0, 0, 0) 100%
    ),
    url(../../assets/banners/singlecaller.jpg) !important;
  background-size: cover;
  background-position: 50%;
  // padding-top: 150px;
  // padding-bottom: 150px;

  &__titlebox {
    text-align: left;
    width: 1280px;
    color: white;

    h1 {
      font-size: 6vw;
      font-weight: 550;
      margin: 0;
      text-shadow: 1px 1px 20px rgba(60, 81, 58, 0.9);
    }
  }
}
.bannerClient {
  height: 50vh;
  width: 100%;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  background-image: linear-gradient(
      90deg,
      rgba(26, 36, 28, 0.95) 30%,
      rgba(0, 0, 0, 0) 100%
    ),
    url(../../assets/banners/teammeeting.jpg) !important;
  background-size: cover;
  background-position: 50%;
  // padding-top: 150px;
  // padding-bottom: 150px;

  &__titlebox {
    text-align: left;
    width: 1280px;
    color: white;

    h1 {
      font-size: 6vw;
      font-weight: 550;
      margin: 0;
      text-shadow: 1px 1px 20px rgba(60, 81, 58, 0.9);
    }
  }
}

.bannerBlog {
  height: 50vh;
  width: 100%;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  background-image: linear-gradient(
      90deg,
      rgba(26, 36, 28, 0.95) 30%,
      rgba(0, 0, 0, 0) 100%
    ),
    url(../../assets/banners/keyboardtyper.jpg) !important;
  background-size: cover;
  background-position: 50%;
  // padding-top: 150px;
  // padding-bottom: 150px;

  &__titlebox {
    text-align: left;
    width: 1280px;
    color: white;

    h1 {
      font-size: 6vw;
      font-weight: 550;
      margin: 0;
      text-shadow: 1px 1px 20px rgba(60, 81, 58, 0.9);
    }
  }
}

.bannerCareers {
  height: 50vh;
  width: 100%;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  background-image: linear-gradient(
      90deg,
      rgba(26, 36, 28, 0.95) 30%,
      rgba(0, 0, 0, 0) 100%
    ),
    url(../../assets/banners/jointeam.jpg) !important;
  background-size: cover;
  background-position: 50%;
  // padding-top: 150px;
  // padding-bottom: 150px;

  &__titlebox {
    text-align: left;
    width: 1280px;
    color: white;

    h1 {
      font-size: 6vw;
      font-weight: 550;
      margin: 0;
      text-shadow: 1px 1px 20px rgba(60, 81, 58, 0.9);
    }
  }
}

.bannerFaq {
  height: 50vh;
  width: 100%;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  background-image: linear-gradient(
      90deg,
      rgba(26, 36, 28, 0.95) 30%,
      rgba(0, 0, 0, 0) 100%
    ),
    url(../../assets/banners/keyboardtyper.jpg) !important;
  background-size: cover;
  background-position: 50%;
  // padding-top: 150px;
  // padding-bottom: 150px;

  &__titlebox {
    text-align: left;
    width: 1280px;
    color: white;

    h1 {
      font-size: 6vw;
      font-weight: 550;
      margin: 0;
      text-shadow: 1px 1px 20px rgba(60, 81, 58, 0.9);
    }
  }
}
